<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn color="primary" @click="addObject()">Добавить</v-btn>
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader v-if="objects.length===0" type="table"></v-skeleton-loader>
            <v-data-table
              v-else
              :items="objects"
              :headers="headers"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" width="600px">
      <edit-object :object="objectToEdit" @close="editDialog = false"></edit-object>
    </v-dialog>
  </v-container>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import EditObject from '@/views/objects/EditObject.vue'

export default {
  name: 'Objects',
  components: { EditObject },
  setup() {
    const objects = computed(() => store.state.objects)
    const headers = ref([
      { text: 'ID', value: 'id' },
      { text: 'Название', value: 'name' },
      { text: 'Город', value: 'city' },
    ])
    const editDialog = ref(false)
    const objectToEdit = ref({})

    const addObject = () => {
      editDialog.value = true
      objectToEdit.value = {}
    }

    return {
      addObject,
      objects,
      headers,
      objectToEdit,
      editDialog,
    }
  },
}
</script>
<style>
</style>

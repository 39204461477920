<template>
  <v-container>
    <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="object.name" label="Название" dense outlined></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="object.city" label="Город" dense outlined></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="object.description" label="Описание" dense outlined />
        </v-col>
        <v-col cols="12">
          <v-file-input
            v-model="object.photos"
            label="Фотографии"
            accept="image/*"
            truncate-length="15"
            multiple
            outlined
          />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="object.discount" label="Скидка" placeholder="1.25" persistent-placeholder outlined dense />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn :loading="saving" color="primary" @click="save()">Сохранить</v-btn>
      <v-btn color="info" @click="close()">Закрыть</v-btn>
    </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import httpClient from '@/services/http.service'
// import store from '@/store'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'EditObject',
  props: {
    object: { type: Object, default: () => {} },
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const save = () => {
      saving.value = true
      const formData = new FormData()
      props.object.photos.forEach((x, i) => {
        formData.append(`photos[${i}]`, x)
      })
      formData.append('name', props.object.name)
      formData.append('city', props.object.city)
      console.log('formData:', formData)
      httpClient.post('objects/create', formData).then(({ data }) => {
        if ('id' in data) {
          store.dispatch('addObject', data)
          emit('close')
        }
        saving.value = false
        console.log('result:', data)
      })
    }

    const close = () => {
      emit('close')
    }
    return {
      save,
      close,
      saving,
    }
  },
}
</script>
<style></style>
